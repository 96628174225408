import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { PostSnippet } from "../../types"
import { BlogList } from "../../components/blogList"
import { SEO } from "../../components/seo/"
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image"

interface Post {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      description: string
      tags: string[]
      img: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
      imgAlt: string
      publishedDate: string
    }
  }
}

interface QueryData {
  data: {
    allMarkdownRemark: {
      edges: Post[]
    }
  }
  pageContext: {
    currentPage: number
    hasNext: boolean
  }
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            imgAlt
            publishedDate
            img {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

export const Page: FunctionComponent<QueryData> = ({ data, pageContext }) => {
  const posts: PostSnippet[] = data.allMarkdownRemark.edges.map(({ node }) => ({
    title: node.frontmatter.title,
    summary: node.frontmatter.description,
    href: node.fields.slug,
    img: node.frontmatter.img.childImageSharp.gatsbyImageData,
    imgAlt: node.frontmatter.imgAlt,
    tags: node.frontmatter.tags,
    publishedDate: new Date(node.frontmatter.publishedDate),
  }))
  const src = getSrc(posts[0].img)
  return (
    <>
      <SEO
        title={`Page ${pageContext.currentPage}`}
        image={src}
        description={`Page ${pageContext.currentPage}`}
      />
      <Layout title="bloglist">
        <BlogList
          posts={posts}
          page={pageContext.currentPage}
          hasNext={pageContext.hasNext}
        />
      </Layout>
    </>
  )
}

export default Page
